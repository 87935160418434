import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button as LinkButton } from 'src/components/button/link';
import Panel from 'src/components/panel/panel';
import { H1 } from 'src/components/typography/typography';
import { getClientConfig, getEnvConfig } from 'src/config/config';

import * as loginLtaStyles from './login-lta.module.less';
import * as loginStyles from './login.module.less';

const { logoFilename, logoAltText } = getClientConfig();

const logo = {
  src: logoFilename && require(`src/images/${logoFilename}`).default,
  altText: logoAltText,
};

const LTA_SSO_URL = `${getEnvConfig().LTA_SSO_BASE_URL}?returnUrl=${getEnvConfig().LTA_SSO_RETURN_URL}`;

const LoginLta = () => {
  const { t } = useTranslation();

  return (
    <div className={loginStyles.loginContainer}>
      {logo.src && <img className={cx(loginStyles.logo, loginLtaStyles.logo)} src={logo.src} alt={logo.altText} />}
      {!logo.src && logo.altText && <H1 spacing={{ margins: { sm: 'bottom' } }}>{logo.altText}</H1>}
      <Panel>
        <div className={loginStyles.formContainer}>
          <div className={loginStyles.headingContainer}>
            <h2 className={loginStyles.signInTitle}>{t('sign in')}</h2>
          </div>

          <LinkButton
            fluid
            size="lg"
            href={LTA_SSO_URL}
            className={loginLtaStyles.linkButton}
            spacing={{ margins: { md: 'vertical' } }}
          >
            Login
          </LinkButton>
        </div>
      </Panel>
    </div>
  );
};

export default LoginLta;
