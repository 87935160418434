import React from 'react';

import Layout from 'src/components/layout/layout';
import LoginDefault from 'src/components/login/login';
import LoginLTA from 'src/components/login/login-lta';
import { getClientConfig } from 'src/config/config';

const loginPageComponent = {
  LTA: LoginLTA,
  DEFAULT: LoginDefault,
};

const LoginPage: React.FC = () => {
  const { loginPageVariant = '' } = getClientConfig();
  const Login = loginPageComponent[loginPageVariant] ?? loginPageComponent.DEFAULT;
  return (
    <Layout nav={false}>
      <Login />
    </Layout>
  );
};

export default LoginPage;
